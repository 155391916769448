<template>
    <div class="con-wrap">
        <CarrotTitle title="CEO Message"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div>
                        <table class="table-row table-serach">
                            <colgroup>
                                <col width="180">
                                <col width="*">
                                <col width="180">
                                <col width="*">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>기간</th>
                                    <td colspan="3">
                                        <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inblock mr-10"></carrot-date-picker>
                                        ~
                                        <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inblock ml-10"></carrot-date-picker>
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td>
                                        <input type="text" class="w-100per" v-model="bbs.title">
                                    </td>
                                    <th>내용</th>
                                    <td>
                                        <input type="text" class="w-100per" v-model="bbs.content">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button class="btn-default float-right mt-10" @click="bbs.getList()">검색</button>
                        <div class="clear"></div>
                    </div>

                    <!-- <div class="tabDefult-col dp-table w-100per mt-50 mb-20">
                        <div class="float-left">
                            <button @click="bbs.setTab('KO')" :class="{ 'on':bbs.ca_name=='KO' }" class="tab-default">국문</button>
                            <button @click="bbs.setTab('EN')" :class="{ 'on':bbs.ca_name=='EN' }" class="tab-default">영문</button>
                        </div>
                        <button type="button" class="btn-default float-right" @click="bbs.showAdd()">새글 작성하기</button>
                    </div> -->
                    <div class="mt-50 mb-20 float-right">
                        <button type="button" class="btn-default" @click="bbs.showAdd()">새글 작성하기</button>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="670">
                            <col width="150">
                            <col width="150">
                            <col width="150">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>제목</th>
                                <th>작성자</th>
                                <th>작성일</th>
                                <th>조회수</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.topfix=='Y'?'-':irow.num }}</td>
                                <td class="txt-left" @click="bbs.showView(irow.idx)">
                                    <span class="btn-view">{{ irow.title }}</span>
                                    <span class="list-num ml-5" v-if="irow.cnt_cmt>0">({{ irow.cnt_cmt }})</span>
                                    <span class="ico-n ml-5" v-if="irow.is_new=='Y'">NEW</span>
                                </td>
                                <td>{{ irow.ename }}({{ irow.kname }})</td>
                                <td>{{ irow.wdate }}</td>
                                <td>{{ irow.hit }}</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="5">등록된 포스트가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.getList"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'


export default {
    layout:"CARROTZone",
    components: {
        CarrotDatePicker
    },
    setup() {
        const router = useRouter()
        const toast  = useToast()

        const bbs = reactive({
            isMounted : false,

            page    : 1,
            rows    : 50,
            sdate   : "",
            edate   : "",
            title   : "",
            content  : "",
            ca_name : "KO",
            list : [], total : 0,

            setTab : (id) => {
                bbs.ca_name = id;
                bbs.page = 1;
                bbs.getList();
            },

            getList: () => {
                if( bbs.isMounted == false ) return;

                let params = {
                    page    : bbs.page,
                    sdate   : bbs.sdate,
                    edate   : bbs.edate,
                    title   : bbs.title,
                    content : bbs.content,
                    ca_name : bbs.ca_name,
                }
                axios.get('/rest/carrotzone/ceomessageList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        // console.info(res.data.err_msg);
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                })
            },
            showView: (idx)=>{
                // location.href = "CEOMessageView/" + idx;
                router.push({
                    name   : 'CARROTZone-CEOMessageView-idx',
                    params : { idx:idx }
                });
            },
            showAdd: () => {
                // location.href = "CEOMessageAdd";
                router.push('CEOMessageAdd');
            }
        });
        
        onMounted(() => {
            // Mounted
            bbs.isMounted = true;

            bbs.getList();
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>